import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaGithub, FaEnvelope } from "react-icons/fa";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div id="aboutus">
      <Container className="about-us-container">
        <Row>
          <Col>
            <h1 className="about-header">About Us</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="about-text">
              We are the users who worked on the development of a user-friendly,
              interactive website for medical students and other healthcare
              professionals that standardizes documentation of neurological
              examination findings, assists in lesion localization, and suggests
              appropriate next steps for further evaluation.
            </p>
            <p className="about-text">Our team members are:</p>
            <ul className="about-list">
              Parsa Hemmati, Ryan Cheung, Reagan Roderiguez, Saqib Rafiq
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="contact-header">Contact Us</h2>
          </Col>
        </Row>
        <Row className="about-links">
          <Col>
            <a href="https://github.com/parsa-hemmati" className="about-link">
              <FaGithub />
              <span>Parsa's GitHub</span>
            </a>
          </Col>
          <Col>
            <a href="mailto:parsa.hemmati@gmail.com" className="about-link">
              <FaEnvelope />
              <span>Parsa's Email</span>
            </a>
          </Col>
          <Col>
            <a href="https://github.com/rbcheung" className="about-link">
              <FaGithub />
              <span>Ryan's GitHub</span>
            </a>
          </Col>
          <Col>
            <a href="mailto:" className="about-link">
              <FaEnvelope />
              <span>Ryan's Email</span>
            </a>
          </Col>
          <Col>
            <a href="https://github.com/reaganrodriguez" className="about-link">
              <FaGithub />
              <span>Reagan's GitHub</span>
            </a>
          </Col>
          <Col>
            <a
              href="mailto:reagan.rodriguez.a@gmail.com"
              className="about-link"
            >
              <FaEnvelope />
              <span>Reagan's Email</span>
            </a>
          </Col>
          <Col>
            <a href="https://github.com/SIR-97" className="about-link">
              <FaGithub />
              <span>Saqib's GitHub</span>
            </a>
          </Col>
          <Col>
            <a href="mailto:saqib.ir@hotmail.com" className="about-link">
              <FaEnvelope />
              <span>Saqib's Email</span>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
