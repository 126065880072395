import React from "react";
import Grid from "@mui/material/Grid";
import { Divider, Typography, IconButton } from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

function Home() {
  return (
    <div id="home">
      <Grid mt={5} mb={5} container>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Typography
            variant="h3"
            sx={{
              justifyContent: "center",
              display: "flex",
              fontWeight: 900,
            }}
            className="heading"
          >
            {" "}
            Welcome to neuroLOGIC!{" "}
            <IconButton
              color="primary"
              edge="start"
              aria-label="logo"
              className="svg_icons"
            >
              <LocalHospitalIcon />
            </IconButton>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              justifyContent: "center",
              display: "flex",
              color: "#595959",
            }}
            className="subheading"
          >
            {" "}
            Your Go-To neurology website for medical students and health-care professionals.{" "}
          </Typography>
          <br></br>
          <Divider></Divider>
          <br></br>
          <h1>Welcome to neuroLOGIC</h1>
          <p>
            NeuroLOGIC is an interactive website designed for medical students
            to learn how to localize neurology lesions through patient history
            taking and physical exam. Our website offers two interactive forms
            for students to enter patient information and receive feedback on
            their localization skills.
          </p>
          <h2>Features</h2>
          <ul>
            <li>
              <strong>Interactive forms:</strong> Our website provides two
              interactive forms - one for history taking and one for physical
              exam - to allow students to practice localizing neurology lesions
              in a structured and interactive way.
            </li>
            <li>
              <strong>Summary:</strong> After completing the form, students can
              download a summary of their history taking or physical exam.
            </li>
            <li>
              <strong>Accessible:</strong> Our website is accessible from
              anywhere with an internet connection, making it easy for students
              to practice and improve their skills at their own pace.
            </li>
          </ul>
          <h2>Get started</h2>
          <p>
            To get started, simply select either the history taking form or the
            physical exam form from the navigation menu above. We recommend
            starting with the history taking form and working your way up to the
            physical exam form as you gain more experience.
          </p>

          <Grid item md={2}></Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
